import React from "react"
import classNames from "classnames"
import HamburgerMenu from "./hamburger-menu"

import "./header.scss"

interface Props {
  theme?: string
  colorTheme?: string
  position?: string
}

export default function Header(props: Props) {
  const classes = classNames("header", {
    "header--dark": props.theme === "black",
    "header--absolute": props.position === "absolute",
  })

  return (
    <div className={classes}>
      <div className="header__inner-container">
        <a className="header__logo-container" href="/">
          <a href="/">
            <img
              className="header__logo"
              src={
                props.theme === "black"
                  ? "/lunta-logo-violet.png"
                  : "/lunta-logo.png"
              }
            />
          </a>
        </a>
        <ul className="header__navigation">
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/portfolio">Projects</a>
          </li>
          <li>
            <a href="/contact-us">Contact us</a>
          </li>
        </ul>
        <div className="header__mobile-navigation">
          <HamburgerMenu colorTheme={props.colorTheme} />
        </div>
      </div>
    </div>
  )
}
