import React from "react"
import "./footer.scss"

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer__inner">
        <div className="footer__first-column">
          <img className="footer__logo" src="/lunta-logo.png" />
          <div>
            Lahnaruohontie 2 B 14 <br />
            00200 Helsinki <br />
            +358 50 428 1765 <br />
            asiakaspalvelu@lunta.fi <br />
            Business ID: 2467667-0
          </div>
        </div>
        <div className="footer__second-column">
          <img
            className="footer__partner-logo"
            src="/shopify-partner-white.png"
          />
        </div>
      </div>
    </div>
  )
}
