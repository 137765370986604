import React from "react"
import { Helmet } from "react-helmet"

interface Props {
  title: string
  description?: string
}

export default function CustomHelmet(props: Props) {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{props.title} - Lunta Labs Oy</title>
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap"
        rel="stylesheet"
      />
      <meta property="og:description" content={props.description} />
    </Helmet>
  )
}
