import React, { useState } from "react"

import "./hamburger-menu.scss"

interface Props {
  colorTheme: string
}

export default function HamburgerMenu(props: Props) {
  const [open, setOpen] = useState(false)

  return (
    <div className="hamburger-menu">
      <div className="hamburger-menu__toggle" onClick={() => setOpen(!open)}>
        <img
          className="hamburger-menu__toggle-icon"
          src={
            props.colorTheme === "white"
              ? "/icon-burger-white.svg"
              : "/icon-burger.svg"
          }
        />
      </div>
      {open && (
        <div className="hamburger-menu__content">
          <div className="hamburger-menu__header">
            <a className="hamburger-menu__logo-container" href="/">
              <img className="header__logo" src="/lunta-logo.png" />
            </a>
            <div
              className="hamburger-menu__toggle"
              onClick={() => setOpen(!open)}
            >
              <img
                className="hamburger-menu__toggle-icon"
                src="/icon-close.svg"
              />
            </div>
          </div>
          <ul className="hamburger-menu__navigation">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/portfolio">Projects</a>
            </li>
            <li>
              <a href="/contact-us">Contact Us</a>
            </li>
          </ul>
        </div>
      )}
    </div>
  )
}
